import { useRouter } from "next/router"
import styled from "styled-components"
import { Button, FlexBox, Hover, Margin, Text } from "@components/ui"

const Style = {
  Wrapper: styled.div`
    width: 100vw;
    height: 100vh;
    background-color: black;
  `,
}

export default function Page() {
  const router = useRouter()
  return (
    <Style.Wrapper>
      <FlexBox
        height="100%"
        column
        justifyContents="center"
        alignItems="center"
      >
        <Text.H3 color="red90">Lost in page</Text.H3>
        <Margin direction="column" size={23} />
        <Text.H3 color="light">{"∑ε( ◦⊙д⊙)‼"}</Text.H3>
        <Margin direction="column" size={84} />
        <FlexBox column justifyContents="center" alignItems="center">
          <Text.H7 color="light">
            죄송합니다. 존재하지 않는 페이지입니다.
          </Text.H7>
          <Text.H7 color="light">하지만 돌아갈 길은 있답니다.</Text.H7>
        </FlexBox>
        <Margin direction="column" size={78} />
        <FlexBox gap={13} justifyContents="center" alignItems="center">
          <Hover onClick={() => router.push("/")}>
            <Button thema="light" text="홈으로" />
          </Hover>
          <Hover onClick={() => router.push("/")}>
            <Button thema="light" text="돌아가기" />
          </Hover>
        </FlexBox>
      </FlexBox>
    </Style.Wrapper>
  )
}
